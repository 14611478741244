import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import scrollTo from "gatsby-plugin-smoothscroll"

const Header = ({ siteTitle }) => {
  const [bgColor, setBgColor] = useState("transparent")

  useEffect(() => {
    const handleScroll = () => {
      const backgroundcolor =
        window.scrollY < 100 ? "transparent" : "dark-purple"

      setBgColor(backgroundcolor)
    }
    document.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <header
      className={`font-body font-semibold fixed w-full py-5 z-20 bg-${bgColor}`}
    >
      <nav className="flex flex-col md:flex-row justify-end items-center text-xs text-white mx-auto max-w-screen-lg">
        <ul className="flex m-0 p-0">
          <li className="m-0 p-0 mr-6">
            <button onClick={() => scrollTo("#about")}>About Me</button>
          </li>
          <li className="m-0 p-0 mr-6">
            <button onClick={() => scrollTo("#services")}>Services</button>
          </li>
          <li className="m-0 p-0">
            <button onClick={() => scrollTo("#contact")}>Contact</button>
          </li>
        </ul>
      </nav>
    </header>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
